// @ts-ignore
import('./bootstrap').then(
  ({ mount }) => {
    const localRoot = document.getElementById('search-and-view-root');

    mount({
      mountPoint: localRoot as HTMLElement,
      initialPathName: '/',
      routingStrategy: 'browser',
    });
  },
);
